import React from 'react'

import Layout from '../components/layout'
import typography from './../typography.module.css'

const TypographyDemo = ({ children }) => (
  <div style={{ display: 'flex', height: 'auto', margin: '1rem' }}>
    {children}
  </div>
)

const Typography = () => (
  <Layout>
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <TypographyDemo>
        <div className={typography.title}>Title</div>
      </TypographyDemo>

      <TypographyDemo>
        <div className={typography.subtitle}>Sub title</div>
      </TypographyDemo>

      <TypographyDemo>
        <div className={typography.title__caption}>title__caption</div>
      </TypographyDemo>

      <TypographyDemo>
        <div className={typography['paragraph--20']}>Paragraph: 20</div>
      </TypographyDemo>
    </div>
  </Layout>
)

export default Typography
