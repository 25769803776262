import { Link } from 'gatsby'
import React from 'react'
import style from './style.module.css'

const Logo = () => (
  <Link className={style.link} to="/">
    entreina<strong>web</strong>
  </Link>
)

export default Logo
