import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import Helmet from 'react-helmet'

import './global.css'
import '../../fonts/font.css'
import './style.css'
import style from './style.module.css'
import favicon from './../../images/favicon.png'
import Header from './../header'
import Footer from '../footer'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet>
          <link rel="icon" href={favicon} type="image/x-icon" />
          <script src="https://connect.facebook.net/en_US/sdk.js"></script>
          <script>
            {`
            window.fbAsyncInit = function () {
              FB.init({
                appId      : '1557989401132033',
                cookie     : true,
                xfbml      : true,
                version    : 'v3.2'
              })
            };
            (function(m,a,i,l,n,g,d){m.GoogleAnalyticsObject=n;m[n]=m[n]||function(){(m[n].q=m[n].q||[]).push(arguments)},m[n].l=1*new Date();g=a.createElement(i),d=a.getElementsByTagName(i)[0];g.async=1;g.src=l;d.parentNode.insertBefore(g,d)})(window,document,"script","//www.google-analytics.com/analytics.js","ga");ga("create","UA-68964929-1","auto");ga("send","pageview");
            
            `}
          </script>

          <script>
            {`!function(h,a,i,c,j,d,g){if(h.fbq){return}j=h.fbq=function(){j.callMethod?j.callMethod.apply(j,arguments):j.queue.push(arguments)};if(!h._fbq){h._fbq=j}j.push=j;j.loaded=!0;j.version="2.0";j.queue=[];d=a.createElement(i);d.async=!0;d.src=c;g=a.getElementsByTagName(i)[0];g.parentNode.insertBefore(d,g)}(window,document,"script","https://connect.facebook.net/en_US/fbevents.js");fbq("init","1557989401132033");fbq("track","PageView");`}
          </script>
          <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/2062482.js"></script>
          <script>{`(function(e,g,d,c,b,f){e.hj=e.hj||function(){(e.hj.q=e.hj.q||[]).push(arguments)};e._hjSettings={hjid:223282,hjsv:5};b=g.getElementsByTagName("head")[0];f=g.createElement("script");f.async=1;f.src=d+e._hjSettings.hjid+c+e._hjSettings.hjsv;b.appendChild(f)})(window,document,"//static.hotjar.com/c/hotjar-",".js?sv=");`}</script>
          <script src="//tag.goadopt.io/injector.js?website_code=f37dc3f2-f5a4-431c-a100-9158317ad3c9" class="adopt-injector"></script>
        </Helmet>
        <Header siteTitle={data.site.siteMetadata.title} />
        <noscript><img height={1} width={1} alt="fbsdk" style={{display:'none'}} src="https://www.facebook.com/tr?id=1557989401132033&ev=PageView&noscript=1" /></noscript>
        <div className={style.container}>{children}</div>
        <Footer />
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
