import React from 'react'
import classnames from 'classnames'
import style from './style.module.css'
import typography from './../../typography.module.css'
import Logo from '../Logo'
import LogoRedfoot from '../LogoRedfoot'
import { container } from './../layout/style.module.css'
import datas from './../../data/footer.yaml'

const Footer = () => (
  <div className={style.footer}>
    <div className={classnames([style.footer__section, container])}>
      <div className={style.footer__section__start}>
        <Logo />
      </div>
      <div className={style.footer__section__middle}>
        <LogoRedfoot />
      </div>
      <nav className={style.footer__section__end}>
        <ul className={style.footer__section__list}>
          {datas.snw.map((network, key) => (
            <li key={key} className={style.footer__section__item}>
              <a
                className={classnames([
                  style.footer__section__link,
                  typography.paragraph14,
                ])}
                href={network.url}
              >
                {network.label}
              </a>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  </div>
)

export default Footer
