import React from 'react'
import classnames from 'classnames'
import style from './style.module.css'
import typography from './../../typography.module.css'
import logoImage from './../../images/home/redfoot.svg'
import routes from './../../data/routes.yaml'

const LogoRedfoot = () => (
  <a
    className={classnames([style.logo, typography.paragraph16])}
    rel="noopener noreferrer"
    href={routes.redfoot}
    target="_blank"
  >
    Uma startup
    <img className={style.logo__image} src={logoImage} alt="Empresa Redfoot" />
  </a>
)

export default LogoRedfoot
