import PropTypes from 'prop-types'
import React from 'react'
import style from './header.module.css'
import Logo from '../Logo'
import routes from '../../data/routes.yaml'

const Header = () => (
  <div className={style.header}>
    <div className={style.container}>
      <h1 className={style.title} style={{ margin: 0 }}>
        <Logo />
      </h1>
      <nav className={style.menu}>
        <ul className={style.list}>
          <li className={style.item}>
            <a className={style.login} href={routes.login}>
              Acessar
            </a>
          </li>
          <li className={style.item}>
            <a className={style.try} href={routes.register}>
              Experimentar grátis
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: '',
}

export default Header
